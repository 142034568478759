<template>
  <Popup v-model="popupShow" position="bottom" :closeable="true" :stylePopup="{height:'100%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="$t('header.About')">
    <div class="detailContainer">
      <div v-if="$i18n.locale === 'zh'">
        <div class="content">
          YCHpro Securities创立于2015年8月1日，前身是“JZD Finance”、“JM finance”、“Huipro”，
          是世界上第一家外汇与差价合约行业开展全天候、不间断、多语言交易直播与投资者教育的公司，并且持续发展至今。2023年8月11日公司更名为YCHpro Securities，并以YCHpro为公司交易直播与投资者教育品牌，是全球领先的金融产品在线交易服务商。YCHpr0寓意着非凡的关怀。
          今天及未来，YCHpro继续尽最大的努力为全球投资者提供最优秀的交易策略与服务。
        </div>
        <br>
        <div class="content">
          周一到周五直播时间：<br>
          中文频道 <br>
          下午18:00--凌晨 0:00(新加波时间)<br>
          10:00AM--4:00PM (GMT)<br><br>
          中文频道 <br>
          下午7:00--下午10:00(新加波时间)<br>
          11:00AM--2:00PM (GMT)
        </div>
      </div>
      <div v-if="$i18n.locale === 'en'">
        <div class="content">
          YCHpro Securities was founded on 1 August 2015, formerly known as ‘JZD Finance’, ‘JM finance’, ‘HUIpro’, and was the first company in the world to launch 24/7, non-stop, multi-language live trading and investor education in the FX and CFD industry, which has continued to evolve to the present day. 11 August 2023 the company changed its name to YCHpro Securities, with YCHpro as the brand for the company's live trading and investor education, and is the world's leading provider of online trading services for financial products. YCHpr0 signifies extraordinary care.
          Today and in the future, YCHpro continues to do its best to provide the best trading strategies and services to global investors.
        </div>
        <div class="content">
          Monday to Friday Live Streaming Time<br>
          Chinese Channel<br>
          18:00PM -- 0:00AM (GMT)<br>
          10:00AM--4:00PM (GMT)<br><br>
          English Channel<br>
          7:00PM--10:00AM (GMT)<br>
          11:00AM--2:00PM (GMT)
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
// import Popup from '@/components/popup/popup';
import { mapGetters } from 'vuex';
import { getBanners } from '@/api';
import Popup from "@/components/popup/popup.vue";

export default {
  name: 'AboutModel',
  components: {
    Popup
    // Popup,
  },
  data() {
    return {
      popupShow: false,
      banners: [],
      titleTop: '',
    };
  },
  computed: {
    images() {
      return this.banners.map(item => item.imgUrl);
    },
    ...mapGetters(['currentRoomId']),
  },
  methods: {
    overlayShow(type) {
      console.log(type, 'khiugftdr');
      if (type === 'hytq') {
        this.titleTop = 'header.Privileges';
      } else {
        this.titleTop = 'header.Profit';
      }
      this.popupShow = true;
      if (this.currentRoomId) {
        getBanners({ roomId: this.currentRoomId, extraFlag: type })
            .then((res) => {
              this.banners = res.data;
            });
      }
    },
    overlayHide() {
      this.banners = [];
      this.popupShow = false;
    },
    openTab(data) {
      if (data.link) {
        window.open(data.link, '_blank');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.detailContainer {
  padding: 20px;
}
.content{
  //text-indent: 2em;
  font-size: 14px;
  line-height: 20px;
}
img {
  width: 100%;
  height: 200px;
}

.title {
  bottom: 0px;
  left: 0;
  right: 0;
  position: absolute;
  height: 40px;
  text-indent: 20px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1111;
}
</style>
